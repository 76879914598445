<template>
  <div id="PlantSummary">
    <VI-Loader class="loader" :active="loading" />
    <div class="column">
      <div class="boxContainer info" v-if="boxPresence.info && infoList && infoList.length">
        <VI-Card :title="translate( boxesConfigs.info.title )">
          <div>
            <table class="infotable">
              <tr v-for="el in infoList" :key="el.id">
                <td style="width:100%">{{ translate( el.label )}}</td>
                <td :title="el.date != '---' ? el.date : null" :style="[ el.old ? { color: oldColor } : null ]" >{{ el.value }}</td>
                <td>{{ el.um }}</td>
              </tr>
            </table>
          </div>
        </VI-Card>
      </div>
      <div class="boxContainer meteo" v-if="boxPresence.meteobox && meteoList && meteoList.length">
        <VI-Card :title="translate( boxesConfigs.meteobox.title )">
          <div class="tachimeterContainer">
            <template v-for="el in meteoList" :key="el.id">
              <div class="tachimeter" v-if="!el.hide">
                <CNS-Tachimeter :value="Number(el.value)" :label="translate( el.label )" :loading="loading" :valueDate="el.utc" :um="el.um" :min="el.min" :max="el.max"/>
              </div>
            </template>
          </div>
        </VI-Card>
      </div>
      <div class="boxContainer stats" v-if="boxPresence.statsbox && alarmsStats">
        <VI-Card :title="translate( boxesConfigs.statsbox.title )">
          <div>
            <table class="statstable">
              <tr v-for="(stat, type) in alarmsStats" :key="type">
                <td>{{ translate( type )}}</td>
                <td class="percentagebarcell">
                  <template v-if="!stat.hide">
                    <div class="percentagebar">
                      <div class="okay" :style="{ width: stat.okay + '%'}" :title="stat.title.okay"></div>
                      <div class="nolink" :style="{ width: stat.nolink + '%'}" :title="stat.title.nolink"></div>
                      <div class="alarm" :style="{ width: stat.alarm + '%'}" :title="stat.title.alarm"></div>
                      <div class="warning" :style="{ width: stat.warning + '%'}" :title="stat.title.warning"></div>
                    </div>
                  </template>
                  <div v-if="stat.hide" class="percentagebar mock"></div>
                </td>
                <td>{{ ( stat.okay || '---' ) + "%" }}</td>
              </tr>
            </table>
          </div>
        </VI-Card>
      </div>
      <div class="boxContainer charts" v-if="boxPresence.chartMonthbox">
        <VI-Card :title="translate( boxesConfigs.chartMonthConf.title )">
          <div class="chart">
            <div class="chartOptions">
              <VI-Rangepicker v-model:value='dataMonthInterval' dropdowns opens="right" />
            </div>
            <div class="chartContainer" >
              <VI-Chart class="chart" ref="chart" title='' subtitle='' :series='chartMonthConf.series' :data='chartMonthConf.data' :options='chartMonthConf.options' :yAxis='chartMonthConf.yAxis' :xAxis='chartMonthConf.xAxis' />
              <VI-Loader v-if="!loading" class="loader" :active="chartMonthLoading" />
            </div>
          </div>
        </VI-Card>
      </div>
    </div>
    <div class="column">
      <div class="boxContainer pr" v-if="boxPresence.prbox && prList && prList.length">
        <VI-Card :title="translate( boxesConfigs.prbox.title )">
          <div class="tachimeterContainer">
            <template v-for="el in prList" :key="el.id">
              <div class="tachimeter" v-if="!el.hide">
                <CNS-Tachimeter :value="validateNum(el.value, generalConf.validate && generalConf.validate[el.variable]) === '---' ? '' : el.value" :label="translate( el.label )" :loading="loading" :valueDate="el.utc" :um="el.um" :min="el.min" :max="el.max" :time="false" />
              </div>
            </template>
          </div>
        </VI-Card>
      </div>
      <div class="boxContainer charts" v-if="boxPresence.chartbox">
        <VI-Card :title="translate( boxesConfigs.chartbox.title )">
          <div class="chart">
            <div class="chartOptions">
              <VI-Select v-model:value="chartType" :options="convertLbl( boxesConfigs.chartbox.options )" />
              <VI-Rangepicker v-model:value='dataInterval' dropdowns opens="left" />
            </div>
            <div class="chartContainer" >
              <VI-Chart v-if="!chartLoading" class="chart" ref="chart" title='' subtitle='' :series='chartConf.series' :data='chartConf.data' :options='chartConf.options' :yAxis='chartConf.yAxis' :xAxis='chartConf.xAxis' />
              <VI-Loader v-if="!loading" class="loader" :active="chartLoading" />
            </div>
          </div>
        </VI-Card>
      </div>
    </div>
  </div>
</template>

<script>
/* global HigJS, visionConf, edw */

// import _ from 'lodash'
import VICard from '../components/VI-Card.vue';
import VIRangepicker from '../components/VI-Rangepicker.vue';
import VIChart from '../components/VI-Chart.vue';
import VISelect from '../components/VI-Select.vue';
import CNSTachimeter from '../components/CNS-Tachimeter.vue';
import VILoader from '../components/VI-Loader.vue';
import Utils from '../libs/utils';
import { DateTime } from 'luxon';

export default {
  name: 'PlantSummary',
  components: {
    VICard,
    VIChart,
    VIRangepicker,
    VISelect,
    CNSTachimeter,
    VILoader
  },
  data () {
    return {
      plantId: undefined,
      plantInfo: undefined,
      plantChildren: undefined,
      infoProm: undefined,
      varPeriods: undefined,
      generalConf: visionConf.summary,
      boxes: visionConf.summary.boxorder,
      boxesConfigs: visionConf.summary.boxconfigs,
      dataInterval: { start: DateTime.utc().startOf('day').toMillis(), stop: DateTime.utc().endOf('day').toMillis() },
      dataMonthInterval: { start: DateTime.utc().startOf('year').toMillis(), stop: DateTime.utc().endOf('month').toMillis() },
      loading: true,
      chartLoading: true,
      oldMultiplier: (visionConf.oldMultiplier && visionConf.oldMultiplier.value) || 5,
      oldColor: (visionConf.oldMultiplier && visionConf.oldMultiplier.color) || 'var(--color-error)',
      chartMonthLoading: true,

      chartType: visionConf.summary.boxconfigs.chartbox.value,
      chartConf: {
        series: [],
        data: {},
        options: {},
        yAxis: {},
        xAxis: {},
        loadingSeries: 0
      },
      chartMonthConf: {
        energyVar: 'energy',
        monthPicker: true,
        series: [],
        data: {},
        options: {
          tooltip: { split: true },
          plotOptions: {
            column: {
              opacity: 0.7,
              grouping: false,
              shadow: false,
              dataLabels: {
                enabled: false
              }
            }
          },
          legend: {
            layout: 'horizontal',
            align: 'left',
            backgroundColor: '#fff',
            verticalAlign: 'top',
            x: 100,
            y: 0,
            floating: true,
            shadow: true,
            itemHoverStyle: {
              color: '#666'
            },
            itemStyle: {
              color: '#333'
            }
          }
        },
        yAxis: {},
        xAxis: {
          labels: {
            value: '${value: %M}' // eslint-disable-line no-template-curly-in-string
          }
        }
      },
      chartDataDaemon: undefined,
      plainDataDaemon: undefined,
      chartAxis: {},
      chartColorIndex: 0,
      infoList: undefined,
      prList: undefined,
      meteoList: undefined,
      alarmsStats: {},
      loadingPlantInfo: false,
      numDec: visionConf.numDec || 2
    };
  },
  computed: {
    plantNode () { return this.$store.state.Nodes.nodes[this.plantId]; },
    activeAlarms () { return this.$store.state.activeAlarms; },
    boxPresence () {
      const presence = {};
      for (const b of this.boxes) { presence[b] = true; }
      return presence;
    }
  },
  watch: {
    $route: {
      // put here initialization of plant relative variables
      // (this is the only function executed when changing between plant pages)
      immediate: true,
      handler: function () {
        if (String(this.plantId) !== String(this.$route.params.plantId)) {
          this.plantId = this.$route.params.plantId;
        }

        this.loadingPlantInfo = false;

        this.getVarPeriods();
        this.$route.name === 'summary' && this.init();
      }
    },
    plantNode: function () {
      if (!this.plantNode) return undefined;
      this.init();
    },
    plantInfo: function () {
      this.init();
    },
    chartType: function () {
      this.clearAxes();
      this.updateSeries();
      // this.chartDataDaemon && this.chartDataDaemon.ticknow();
    },
    dataInterval: function () {
      this.chartLoading = true;
      this.updateSeries();
    },
    dataMonthInterval: function () {
      this.chartMonthLoading = true;
      this.getData();
    },
    activeAlarms: function () {
      this.updateAlarms();
    }
  },
  methods: {
    validateNum (value, settings = undefined, nodeId = undefined) {
      return Utils.validateNum(value, settings, nodeId);
    },

    async getVarPeriods () {
      if (!this.varPeriods) {
        this.varPeriods = await this.$getVarPeriods();
      }
    },
    convertLbl (options) {
      if (!Array.isArray(options)) return options;

      const converted = [];
      for (const o of options) {
        o.lbl = this.translate(o.lbl);
        converted.push(o);
      }

      return converted;
    },
    translate (id) {
      return edw(id);
    },
    reflowPage () {
      console.log('Page reflow called!');
      this.$nextTick(() => {
        this.$refs.chart && this.$refs.chart.reflow();
      });
    },
    loadPlantInfo () {
      if (!this.plantNode.parameters || this.loadingPlantInfo) return;
      let type;
      this.loadingPlantInfo = true;

      const promArray = [this.$getPlantInfo(this.plantNode.parameters.plantRefId.val)];
      const typeList = new Set(this.boxesConfigs.statsbox.list || []);

      for (type of typeList) {
        promArray.push(this.$getNodeChildsOfType(this.plantId, type));
      }

      return Promise.all(promArray)
        .then((replies) => {
          this.plantInfo = replies[0];
          replies.splice(0, 1);
          let i;
          i = 0;
          this.plantChildren = {};
          for (type of typeList) {
            this.plantChildren[type] = new Set((replies[i] || []).map(function (el) { return +el.id; }));
            if (this.plantChildren[type].size) {
              this.alarmsStats[type] = { total: this.plantChildren[type].size, hide: true };
            }
            i++;
          }
        })
        .catch((error) => { HigJS.debug.error(error); });
    },
    start () {
      let el, tmp;
      const lists = {};
      const reqs = {};
      const trace = {};

      this.updateAlarms();
      this.updateSeries();
      if (this.boxPresence.chartMonthbox) {
        this.getData();
      }

      if (this.boxesConfigs.info && Array.isArray(this.boxesConfigs.info.list)) {
        lists.info = [];
        let info;

        for (el of this.boxesConfigs.info.list) {
          info = { id: el.id };
          info.value = '---';
          info.um = '';
          info.label = edw(el.label);

          if (el.source === 'parameter') {
            if (!this.checkExistence(el)) continue;
            info.value = HigJS.num.format(Utils.round(HigJS.obj.getObjDeep(this.plantNode, ['parameters', el.name, 'val'].join('.')), this.numDec || 2)) || info.value;
            info.um = HigJS.obj.getObjDeep(this.plantNode, ['parameters', el.name, 'um'].join('.')) || info.um;
            !info.um && el.um && (info.um = el.um);
          } else if (el.source === 'info') {
            if (!this.checkExistence(el)) continue;
            info.value = HigJS.obj.getObjDeep(this.plantInfo, [el.name].join('.')) || info.value;
            info.um = el.um || null;
            !info.um && el.um && (info.um = el.um);
          } else if (el.source === 'data') {
            if (!this.checkExistence(el)) continue;
            info.nodeId = this.plantId;
            info.um = HigJS.obj.getObjDeep(this.plantNode, ['parameters', el.variable, 'um'].join('.')) || info.um;
            !info.um && el.um && (info.um = el.um);
            info = reqs[el.id] = Object.assign(info, el);
            info.value = '---';
          }
          trace[info.id] = { path: ['infoList'], style: el.style };
          tmp = lists.info.push(info);
          trace[info.id].path.push(tmp - 1);
        }
        this.infoList = lists.info;
      }

      if (this.boxesConfigs.prbox && Array.isArray(this.boxesConfigs.prbox.list)) {
        let pr;
        lists.pr = [];
        for (el of this.boxesConfigs.prbox.list) {
          if (!this.checkExistence(el)) continue;
          pr = { nodeId: this.plantId };
          reqs[el.id] = Object.assign(pr, el);
          trace[el.id] = { path: ['prList'], style: el.style };
          tmp = lists.pr.push(reqs[el.id]);
          trace[el.id].path.push(tmp - 1);
        }
        this.prList = lists.pr;
      }

      if (this.boxesConfigs.meteobox && Array.isArray(this.boxesConfigs.meteobox.list)) {
        let meteo;
        lists.meteo = [];
        for (el of this.boxesConfigs.meteobox.list) {
          if (!this.checkExistence(el)) continue;
          meteo = { nodeId: this.plantId };
          reqs[el.id] = Object.assign(meteo, el);
          trace[el.id] = { path: ['meteoList'], style: el.style };
          tmp = lists.meteo.push(reqs[el.id]);
          trace[el.id].path.push(tmp - 1);
        }
        this.meteoList = lists.meteo;
      }

      this.plainDataDaemon && this.plainDataDaemon.stop();
      this.plainDataDaemon = this.$getDataDaemon(reqs, (reply) => {
        let track, tmp, style;
        for (const r in reply) {
          track = trace[r].path;
          style = trace[r].style || {};

          tmp = reply[r] && reply[r][0];

          this[track[0]][track[1]].hide = !tmp;

          this[track[0]][track[1]].value = track[0] === 'infoList' ? HigJS.num.format(this.transformValue(style.value, tmp && tmp[1])) : this.transformValue(style.value, tmp && tmp[1]);
          this[track[0]][track[1]].utc = this.transformUtc(style.utc, tmp && tmp[0]);
          track[0] === 'infoList' && (this[track[0]][track[1]].old = this.checkOld(this[track[0]][track[1]].utc, this[track[0]][track[1]].period));
          track[0] === 'infoList' && (this[track[0]][track[1]].date = HigJS.num.formatDate({ utc: this[track[0]][track[1]].utc, date: true, time: true }));
        }
      }, Utils.ms('15s'));
      this.loading = false;
    },
    transformValue (style, value) {
      if (value == null || !style) return value;
      if (style === '2digits') return (+value).toFixed(2);
      if (style === 'undefined' && value == null) return undefined;
      return Utils.round(value, this.numDec || 2);
    },
    transformUtc (style, value) {
      if (value == null || !style) return value;
      if (style === 'sec') return parseInt(value / 1000);
      return value;
    },
    init () {
      if (!this.plantNode) return;
      if (!this.plantInfo) {
        this.infoProm = this.loadPlantInfo();
      } else {
        if (!this.infoProm) {
          this.infoProm = Promise.resolve();
        } else {
          return;
        }
      }

      if (this.infoProm) {
        return this.infoProm.then(() => {
          this.start();
        });
      }
    },
    updateSeries () {
      if (!this.plantNode.parameters) return;
      let series;
      const reqs = {};
      const localNumDec = this.numDec;
      series = this.boxesConfigs.chartbox.series[this.chartType];
      series = HigJS.obj.advClone(series);
      if (!series) return undefined;

      this.chartConf.series.splice(0, this.chartConf.series.length);

      const positioner = function (tAmount, axisMax) {
        const positions = [];
        for (let i = 0; i < tAmount; i++) {
          positions.push(Math.round(((axisMax / tAmount) * i) * 100) / 100);
        }
        positions.push(axisMax);
        return function () {
          return positions;
        };
      };
      for (const s of series) {
        let onlyDate = false;
        if (this.dataInterval.stop - this.dataInterval.start >= 259200000) {
          s.dataIntervalOver = true;
          if (s.dynamicMax) onlyDate = true;
          s.period = s.dynamic || s.period;
          s.max = s.dynamicMax ? (typeof (s.dynamicMax) === 'function') ? s.dynamicMax.call(this) : s.dynamicMax : s.max;
        }

        const tickAmount = 6;
        this.chartConf.yAxis[s.um] = {
          title: { text: edw(s.label) + (s.um ? ' [' + s.um + ']' : '') },
          opposite: !!s.opposite,
          min: 0,
          tickPositioner: s.tickPositioner ? positioner.apply(this, [tickAmount, ((typeof (s.max) === 'function') ? s.max.call(this) : s.max) * 1.2]) : undefined,
          labels: { formatter: function () { return HigJS.num.format(Utils.round(this.value, localNumDec || 2)); } }
        };

        this.chartConf.options = {
          tooltip: {
            formatter: function () {
              const tooltips = this.points.map(function (point) {
                return point.series.name + ': ' + HigJS.num.format(Utils.round(point.y, localNumDec || 2));
              });

              tooltips.unshift(HigJS.num.formatDate({ date: onlyDate, time: !onlyDate, utc: this.points[0].x / 1000 }));
              return tooltips;
            },
            split: true
          }
        };

        this.chartConf.series.push({
          id: s.id,
          name: edw(s.label),
          yAxis: s.um,
          type: s.chartType || 'line',
          marker: { enabled: true },
          color: s.color ? s.color : undefined,
          turboThreshold: 0
        });

        reqs[s.id] = Object.assign(s, { nodeId: this.plantId, interval: this.dataInterval });
      }

      this.chartConf.reqs = reqs;
      this.chartDataDaemon && this.chartDataDaemon.stop();
      this.chartDataDaemon = this.$getDataDaemon(this.chartConf.reqs, (reply) => {
        for (const r in reply) {
          if (!reply[r]) {
            reply[r] = [];
          } else {
            for (const item in reply[r]) {
              if (reply[r][item] && reply[r][item][1] && typeof reply[r][item][1] === 'string') {
                reply[r][item][1] = parseFloat(reply[r][item][1]);
              }
            }

            if (this.chartConf.reqs[r].type === 'raw' && reply[r].length > 0) {
              if (this.chartConf.reqs[r].dataIntervalOver && this.chartConf.reqs[r].dynamic && this.chartConf.reqs[r].dynamic > 0) {
                reply[r] = reply[r].filter((value, index) => {
                  return index % this.chartConf.reqs[r].dynamic === 0;
                });
              }
              const lastIndex = reply[r].length - 1;
              const dateTmp = new Date(reply[r][lastIndex][0]);
              const offset = dateTmp.getTimezoneOffset() * 60000;
              const date = new Date(dateTmp.getTime() + offset);
              date.setHours(23, 59, 59);

              if (reply[r][lastIndex][0] < date.getTime()) {
                reply[r].push([date.getTime(), null]);
              }
            }
          }
        }

        this.chartConf.data = reply;
        this.chartLoading = false;
        // this.$refs.chart && this.$refs.chart.chart.redraw( false );
      }, Utils.ms('15s'));
    },
    getData () {
      if (!this.plantNode.parameters) return;
      let series;
      const reqs = {};
      const localNumDec = this.numDec;
      series = this.boxesConfigs.chartMonthConf.series;
      series = HigJS.obj.advClone(series);
      if (!series) return undefined;

      this.chartMonthConf.series.splice(0, this.chartMonthConf.series.length);
      if (this.boxesConfigs.chartMonthConf.options) {
        this.chartMonthConf.options.plotOptions = this.boxesConfigs.chartMonthConf.options;
      }

      let pointPadding = 0;
      for (const s of series) {
        if (this.dataInterval.stop - this.dataInterval.start >= 259200000) {
          s.dataIntervalOver = true;
          s.period = s.dynamic || s.period;
          s.max = s.dynamicMax ? (typeof (s.dynamicMax) === 'function') ? s.dynamicMax.call(this) : s.dynamicMax : s.max;
        }

        this.chartMonthConf.yAxis[s.um] = {
          title: { text: edw(s.label) + (s.um ? ' [' + s.um + ']' : '') },
          opposite: !!s.opposite,
          min: 0,
          labels: { formatter: function () { return HigJS.num.format(Utils.round(this.value, localNumDec || 2)); } }
        };

        this.chartMonthConf.series.push({
          id: s.id,
          name: edw(s.label),
          yAxis: s.um,
          type: s.chartType || 'column',
          marker: { enabled: true },
          color: s.color ? s.color : undefined,
          gain: s.gain || undefined,
          turboThreshold: 0,
          pointPadding: pointPadding
        });

        pointPadding = pointPadding + 0.1;
        reqs[s.id] = Object.assign(s, { nodeId: this.plantId, interval: this.dataMonthInterval });
      }

      this.chartMonthConf.reqs = reqs;
      this.$getData(this.chartMonthConf.reqs).then((reply, err) => {
        const map = this.chartMonthConf.series.map((el) => [el.id, el.gain]);
        const gain = Object.fromEntries(map);
        if (err) {
          console.error(err);
        } else {
          this.chartMonthConf.data = {};
          for (const r in reply) {
            if (!reply[r]) {
              reply[r] = [];
            } else {
              for (const item in reply[r]) {
                if (gain[r]) {
                  reply[r][item][1] *= gain[r];
                }
                if (reply[r][item] && reply[r][item][1] && typeof reply[r][item][1] === 'string') {
                  reply[r][item][1] = parseFloat(reply[r][item][1]);
                }
              }
            }
          }
          this.chartMonthConf.data = reply;
          this.chartMonthLoading = false;
        }
      });
    },
    updateAlarms () {
      this.alarmsStats = this.computeAlarmsStats(this.activeAlarms);
    },
    clearAxes () {
      for (const ax in this.chartConf.yAxis) {
        delete this.chartConf.yAxis[ax];
      }
    },
    checkExistence (el) {
      let flag;
      switch (el.source) {
        case 'data':
          if (el.type === 'agg') {
            el.period = Utils.convertVarPeriod(el.period, this.varPeriods);
            flag = HigJS.obj.getObjDeep(this.plantNode, ['variables', el.period, el.variable].join('.')) != null;
          } else { flag = HigJS.obj.customHasOwnProperty({ target: this.plantNode, key: ['parameters', el.variable].join('.') }); }
          break;
        case 'info':
          flag = HigJS.obj.customHasOwnProperty({ target: this.plantInfo, key: el.name });
          break;
        case 'parameter':
          flag = HigJS.obj.customHasOwnProperty({ target: this.plantNode, key: ['parameters', el.name].join('.') });
          break;
      }
      return flag;
    },
    computeAlarmsStats (alarms) {
      if (!this.plantChildren) return {};
      let level, ntName, type;
      const available = new Set(this.boxesConfigs.statsbox.list || []);
      const priority = this.boxesConfigs.statsbox.priorityLevel;
      const stats = {};

      for (type of available) {
        if (this.plantChildren[type].size) {
          stats[type] = {
            total: this.plantChildren[type].size,
            okay: this.plantChildren[type].size,
            nolink: 0,
            alarm: 0,
            warning: 0,
            list: { }
          };
        }
      }

      for (const al of alarms) {
        if (String(al.plantNodeId) !== String(this.plantId)) continue;
        /* Start flow control */
        /* If Fabio modification works */

        // if ( !available.has( al.ntName )) continue;
        // if ( !this.plantChildren[ al.ntName ].has( + al.nodeId )) continue;
        // ntName = al.ntName

        /* else or anyway */

        ntName = null;
        for (type in stats) {
          if (this.plantChildren[type].has(+al.nodeId)) {
            ntName = type;
            break;
          }
        }

        if (!ntName) continue;

        /* end of flow control */

        level = { current: priority.indexOf(al.levelType) };
        if (!stats[ntName].list[al.nodeId]) {
          stats[ntName].list[al.nodeId] = level.current;
        } else {
          level.prior = stats[ntName].list[al.nodeId];
          stats[ntName].list[al.nodeId] = Math.min(level.current, level.prior);
        }
      }

      for (let stat in stats) {
        stat = stats[stat];
        for (const node in stat.list) {
          stat[priority[stat.list[node]]]++;
          stat.okay--;
        }

        stat.nolink = (stat.nolink / stat.total * 100).toFixed(1);
        stat.alarm = (stat.alarm / stat.total * 100).toFixed(1);
        stat.warning = (stat.warning / stat.total * 100).toFixed(1);
        stat.okay = (100 - stat.nolink - stat.alarm - stat.warning).toFixed(1);

        stat.title = {
          okay: 'Working ' + stat.okay + '%',
          nolink: 'No link ' + stat.nolink + '%',
          alarm: 'Alarm ' + stat.alarm + '%',
          warning: 'Warning ' + stat.warning + '%'
        };

        delete stat.list;
      }

      return stats;
    },
    checkOld (utc, period) {
      if (!this.varPeriods || !utc || !period) return false;
      let fullPeriod;

      const checkedPeriod = Utils.convertVarPeriod(period, this.varPeriods);

      this.varPeriods.forEach(period => {
        if (String(period.name) === String(checkedPeriod)) fullPeriod = period;
      });

      if (!fullPeriod) return false;

      const correctedUtc = this.utcCorrected(utc * 1000, this.plantInfo.timezone);

      return Utils.checkUtcOld(correctedUtc, fullPeriod, this.oldMultiplier);
    },
    utcCorrected (utc, zone) {
      if (!zone) return utc;
      let correct = DateTime.fromMillis(utc).toUTC().toFormat('yyyy LLL dd HH mm ss');
      correct = DateTime.fromFormat(correct, 'yyyy LLL dd HH mm ss', { zone: zone }).toMillis();

      return correct;
    }
  },
  mounted () {
    window.addEventListener('resize', this.reflowPage);
  },
  unmounted () {
    document.body.removeEventListener('resize', this.reflowPage);
    this.plainDataDaemon && this.plainDataDaemon.stop();
    this.chartDataDaemon && this.chartDataDaemon.stop();
  }
};
</script>

<style scoped>
#PlantSummary {
  position:relative;
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  height: 0;
  width: 100%;
  overflow-y: auto
}

.column {
  flex: 0 1 50%;
  display: flex;
  flex-flow: column;
}

.boxContainer {
  flex: 0 1 30%;
  padding: 3px;
  display: flex;
  flex-flow: column;
  max-width: 100%;
  min-width: 40%;
  width: 100%;
  width: auto;
}

.boxContainer.info {
  flex: 0 0 auto;
}

.boxContainer.pr {
  flex: 0 1 auto;
}

.boxContainer.meteo {
  flex: 0 1 auto;
}

.boxContainer.charts {
  flex: 1 0 60%;
}

.boxContainer.stats {
  flex: 0 0 auto;
  min-width: 50%;
}

.box {
  border-radius: 3px;
  background: #455e74;
  flex: 1 1 auto;
  height: 0;
}

.tachimeterContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}

.tachimeterContainer > .tachimeter {
  height: auto;
  width: 200px;
  height: 160px;
  padding: 15px;
}

.chart {
  display: flex;
  flex-flow: column;
  flex: 1 1 100%;
  height: 0;
}

.chart :deep(.highcharts-container) {
  margin: auto;
  height: 100%;
}

.chart > .chartOptions {
  display: flex;
  flex-flow: row;
  flex: 0 0 auto;
  width: 100%;
  justify-content: space-between;
}

.chart > .chartContainer {
  position: relative;
  display: flex;
  flex-flow: row;
  flex: 1 1 100%;
  width: 100%;
  height: 0;
}

table.infotable {
  width: 70%;
  min-width: 360px;
  margin: auto;
  padding: 10px 20px;
}

table.infotable td:first-of-type,
table.infotable td:last-of-type {
  text-align: left;
}

table.statstable {
  width: 100%;
  padding: 10px 20px;
}

table.statstable td:first-of-type {
  text-align: left;
  white-space: nowrap;
}

table.statstable td:first-of-type {
  text-align: right;
  white-space: nowrap;
}

table.statstable  td:nth-of-type(2) {
  text-align: center;
  width: 100%;
}

table.statstable .percentagebar {
  width: 100%;
  height: var( --height );
  display: block;
  --okay: rgb(0, 136, 101);
  --nolink: rgb(92, 102, 107);
  --warning: rgb(243, 183, 1);
  --alarm: rgb(142, 38, 38);
  --height: 30px;
  --loading: rgb(92, 102, 107, 30 );
}

table.statstable .percentagebar.mock {
  background: var( --loading );
  opacity: 0.6;
}

table.statstable .percentagebar div {
  height: var( --height );
  display: inline-block;
}

table.statstable .percentagebar .okay {
  background: var( --okay );
}

table.statstable .percentagebar .nolink {
  background: var( --nolink );
}

table.statstable .percentagebar .alarm {
  background: var( --alarm );
}

table.statstable .percentagebar .warning {
  background: var( --warning );
}

.VI-Card {
  /*height: 100%;*/
  display: flex;
  flex-flow: column;
  flex: 1 1 100%;
  height: 0;
}
</style>
